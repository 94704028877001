import React from "react";
import { Tag, Popconfirm, Space, message, Row, Col } from "antd";
import { showConfirmDlgWidget } from "components/ConfirmDlgWidget/showConfirmDlgWidget";
import { DeleteOutlined, EditFilled, EditOutlined, MailOutlined, UserAddOutlined } from "@ant-design/icons";
export function showColumnAction(info, onRegister) {
  return (
    <Row gutter={16}>
      <Col>
        <a
          style={{ color: "var(--mainColor)" }}
          onClick={async () => {
            onRegister(info);
          }}
        >
          <UserAddOutlined /> Register
        </a>
      </Col>
    </Row>
  );
}
