import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import {
  DollarCircleOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Col, Row, Badge, Dropdown, Space, Menu, message } from "antd";
import ButtonWithIcon from "components/ButtonWidgets/ButtonWithIcon/ButtonWithIcon";
import { IconWidget } from "components/IconWidget/IconWidget";
import {
  MENU_ITEMS,
  ROOT,
  ROUTE_DASHBOARD,
  ROUTE_NOTIFICATION,
  ROUTE_NOTIFICATIOn,
  ROUTE_PAYMENT,
} from "navigation/CONSTANTS";
import {
  ROUTE_LOGIN,
  GetCurrentMainRoute,
  ROUTE_USER_PROFILE,
  ROUTE_VENDOR_PROFILE,
} from "navigation/CONSTANTS";
import {
  ChangeMenuCollapsed,
  UpdateCurrentRoot
} from "redux/actions/appActions";
import collapseIcon from "../../assets/icons/ic_collapse.svg";
import "./TopbarLayout.css";
import { updateBalance, updateClose } from "redux/actions/userActions";
import { showConfirmDlgWidget } from "components/ConfirmDlgWidget/showConfirmDlgWidget";
import { numberWithCommas } from "utils/GlobalFunctions";

function TopbarLayout(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const appDataStore = useSelector((x) => x.appDataStore);
  const { collapsed, width, currentRoute } = appDataStore;
  const userDataStore = useSelector((x) => x.userDataStore);

  const url = history.location.pathname;
  const mainRoute = GetCurrentMainRoute(url);
  const selMenu = MENU_ITEMS.filter((x) => x.route == mainRoute)[0];



  const userInfo = userDataStore;
  if (userDataStore === undefined && currentRoute == ROUTE_DASHBOARD) {
    return <div>{props.children}</div>;
  }
  const menuWidget = () => {
    return (
      <div className="menu-widget">
        <div
          onClick={() => {
            dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_USER_PROFILE }));
          }}
        >
          <Link to={ROUTE_USER_PROFILE} className={"menu-widget-item"}>
            <UserOutlined className={"menu-widget-item-icon"} /> User Profile
          </Link>
        </div>
        <div
          onClick={() => {
            dispatch(
              UpdateCurrentRoot({ currentRoute: ROUTE_VENDOR_PROFILE })
            );
          }}
        >
          <Link to={ROUTE_VENDOR_PROFILE} className={"menu-widget-item"}>
            <ShoppingCartOutlined className={"menu-widget-item-icon"} />{" "}
            Vendor Profile
          </Link>
        </div>
        <div
          onClick={() => {
            dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_PAYMENT }));
          }}
        >
          <Link to={ROUTE_PAYMENT} className={"menu-widget-item"}>
            <DollarCircleOutlined className={"menu-widget-item-icon"} /> Payment
          </Link>
        </div>
      </div>
    );
  };

  if (width <= 1024) {
    return (
      <div>
        <div className="logo">
          <img src={"/assets/images/logo.png"} alt="Beclutch" className="logo-img" />
          <div
            className="side-collapse"
            onClick={() => {
              dispatch(ChangeMenuCollapsed({ collapsed: !collapsed }));
            }}
          >
            <IconWidget Icon={collapseIcon} />
          </div>
        </div>
        <Row align={"center"}>
          <span className="txt-top-welcome">Welcome Back!</span>{" "}
          <span className="txt-top-name">{userInfo.first_name}</span>
        </Row>
        <Row align={"center"} style={{ marginTop: "20px" }}>
          <Row
            className="top-setting-bg"
            justify={"space-between"}
            align={"middle"}
          >
            <Col className="gutter-row">
              <div>
                <img
                  className="user-profile-image"
                  onClick={() => {
                    // dispatch(
                    //   UpdateCurrentRoot({ currentRoute: ROUTE_USER_PROFILE })
                    // );
                    // history.push(ROUTE_USER_PROFILE);
                  }}
                  src={
                    userInfo.image_url == null || userInfo.image_url == ""
                      ? "/assets/icons/ic_logout.svg"
                      : userInfo.image_url
                  }
                  alt=""
                />
                <span
                  className="txt-top-payment"
                  onClick={() => {
                    dispatch(
                      UpdateCurrentRoot({ currentRoute: ROUTE_PAYMENT })
                    );
                    history.push(ROUTE_PAYMENT);
                  }}
                  style={
                    userDataStore.balance !== undefined &&
                      userDataStore.balance < 0
                      ? { color: "var(--redColor)" }
                      : { color: "var(--blueColor)" }
                  }
                >
                  {userDataStore.balance === undefined
                    ? ""
                    : (userDataStore.balance >= 0 ? "" : "-") +
                    "R" +
                    numberWithCommas(Math.abs(userDataStore.balance))}
                </span>
                <span
                  className="txt-top-logout"
                  onClick={() => {
                    history.push(ROOT);
                  }}
                >
                  Log out
                </span>
              </div>
            </Col>
            <Col className="gutter-row"></Col>

          </Row>
        </Row>
        <Row align={"left"}>
          <span className="txt-top-title">{selMenu["title"]}</span>
        </Row>
        <div>{props.children}</div>
      </div>
    );
  }
  return (
    <div>
      <div className="top-navbar-layout">
        <div className="top-navbar-header">
          <Row align={"middle"} gutter={16} justify={"space-between"}>
            <Col>
              <span className="txt-top-title">{selMenu["title"]}</span>
            </Col>
            <Col style={{ textAlign: "right" }}>
              <Row align={"middle"}>
                <Col align={"middle"}>
                  <span className="txt-top-welcome">Welcome Back!</span>{" "}
                  <span className="txt-top-name">{userInfo.first_name}</span>
                </Col>
                <Col>
                  <Row
                    className="top-setting-bg"
                    justify={"space-between"}
                    align={"middle"}
                  >
                    <Col className="gutter-row">
                      <div>
                        <img
                          className="user-profile-image"
                          onClick={() => {
                            // dispatch(
                            //   UpdateCurrentRoot({
                            //     currentRoute: ROUTE_USER_PROFILE,
                            //   })
                            // );
                            // history.push(ROUTE_USER_PROFILE);
                          }}
                          src={
                            userInfo.image_url == null ||
                              userInfo.image_url == ""
                              ? "/assets/icons/ic_logout.svg"
                              : userInfo.image_url
                          }
                          alt=""
                        />
                        <span
                          className="txt-top-payment"
                          onClick={() => {
                            dispatch(
                              UpdateCurrentRoot({ currentRoute: ROUTE_PAYMENT })
                            );
                            history.push(ROUTE_PAYMENT);
                          }}
                          style={
                            userDataStore.balance !== undefined &&
                              userDataStore.balance < 0
                              ? { color: "var(--redColor)" }
                              : { color: "var(--blueColor)" }
                          }
                        >
                          {userDataStore.balance === undefined
                            ? ""
                            : (userDataStore.balance >= 0 ? "" : "-") +
                            "R" +
                            numberWithCommas(Math.abs(userDataStore.balance))}
                        </span>
                        <span
                          className="txt-top-logout"
                          onClick={() => {
                            history.push(ROOT);
                          }}
                        >
                          Log out
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div>{props.children}</div>
    </div>
  );
}

export default withRouter(TopbarLayout);
