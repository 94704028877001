import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlRegisterUser } from "./CONSTANTS";
import api from "./api.js";

export const apiRegisterUser = async (param) => {
  const params = new FormData();
  params.append("registration_id", param.registration_id);
  params.append("registration_name", param.registration_name);
  params.append("yaccamp", param.yaccamp);
  params.append("reg_type", param.reg_type);
  params.append("camper_info", JSON.stringify(param.camperInfo));
  params.append("camper_reg", param.camper_reg_checked);
  params.append("camper_reg_id", param.camper_reg_sel_info ? param.camper_reg_sel_info.ID : "");
  params.append("p_cell_phone", param.p_cell_phone);
  params.append("p_email", param.p_email);
  params.append("p_first_name", param.p_first_name);
  params.append("p_home_phone", param.p_home_phone == undefined ? "" : param.p_home_phone);
  params.append("p_last_name", param.p_last_name);
  params.append("p_relation", param.p_relation);


  params.append("a_cell_phone", param.a_cell_phone);
  params.append("a_email", param.a_email);
  params.append("a_first_name", param.a_first_name);
  params.append("a_home_phone", param.a_home_phone);
  params.append("a_last_name", param.a_last_name);
  params.append("a_relation", param.a_relation);
  params.append("c_cell_phone", param.c_cell_phone);
  params.append("c_email", param.c_email);
  params.append("c_first_name", param.c_first_name);
  params.append("c_last_name", param.c_last_name);
  params.append("a_park_id", param.a_park_id);
  params.append("a_adults", param.a_adults);
  params.append("a_spouse", param.a_spouse);
  params.append("a_spouse_anwser", param.a_spouse_anwser);
  params.append("a_c_cell_phone", param.a_c_cell_phone);
  params.append("a_c_email", param.a_c_email);
  params.append("a_c_first_name", param.a_c_first_name);
  params.append("a_c_last_name", param.a_c_last_name);
  params.append("bill_address", param.bill_address);
  params.append("bill_city", param.bill_city);
  params.append("bill_postal_code", param.bill_postal_code);
  params.append("bill_state", param.bill_state);

  params.append("discount_code", param.discount_code == undefined ? "" : param.discount_code);
  params.append("discount_amount", param.discount_amount);
  params.append("processing_fee", param.processing_fee);
  params.append("total_checkout", param.total_checkout);
  params.append("total_amount", param.src_total_amount);
  params.append("payment_gateway", param.isStax ? "stax" : "forte");
  params.append("payment_method", param.payment_method);
  params.append("transaction_id", param.transaction_id);
  params.append("entered_by", param.entered_by);
  params.append("authorization_code", param.authorization_code);
  params.append("location_id", param.location_id);
  for (let i = 0; i < param.recordList.length; i++) {
    params.append("camper_records[]", param.recordList[i]);
  }

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const { data } = await api.post("camp/register_user", params, config);

  return data;
};

export const apiRegisterFailedPayment = async (param) => {
  const params = new FormData();
  params.append("err_res", param.err_res);
  params.append("api_name", param.api_name);
  params.append("p_cell_phone", param.p_cell_phone);
  params.append("p_email", param.p_email);
  params.append("p_first_name", param.p_first_name);
  params.append("p_home_phone", param.p_home_phone == undefined ? "" : param.p_home_phone);
  params.append("p_last_name", param.p_last_name);
  params.append("discount_code", param.discount_code == undefined ? "" : param.discount_code);
  params.append("discount_amount", param.discount_amount);
  params.append("processing_fee", param.processing_fee);
  params.append("total_checkout", param.total_checkout);
  params.append("total_amount", param.src_total_amount);
  params.append("payment_method", param.card_type);

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const { data } = await api.post("camp/register_failed_user", params, config);

  return data;
};