import React, { useEffect, useState } from "react";
import StepOne from "./StepOne/StepOne";
import StepTwo from "./StepTwo/StepTwo";
import StepThree from "./StepThree/StepThree";

const StepInfo = (props) => {
  const {
    userData,
    setUserData,
    errorField,
    setErrorField,
    homePhone,
    setHomePhone,
    cellPhone,
    setCellPhone,
    contactPhone,
    setContactPhone,
    acontactPhone,
    setAContactPhone,
    aCellPhone,
    setACellPhone,
    aHomePhone,
    setAHomePhone,
    recordList,
    setRecordList,
    regType,
    curDomain
  } = props;

  useEffect(() => {
    if (cellPhone != "" && cellPhone != undefined) {
      setUserData({ ...userData, p_cell_phone: cellPhone });
    }
  }, [cellPhone]);
  useEffect(() => {
    if (homePhone != "" && homePhone != undefined) {
      setUserData({ ...userData, p_home_phone: homePhone });
    }
  }, [homePhone]);

  useEffect(() => {
    if (aCellPhone != "" && aCellPhone != undefined) {
      setUserData({ ...userData, a_cell_phone: aCellPhone });
    }
  }, [aCellPhone]);
  useEffect(() => {
    if (aHomePhone != "" && aHomePhone != undefined) {
      setUserData({ ...userData, a_home_phone: aHomePhone });
    }
  }, [aHomePhone]);
  useEffect(() => {
    if (contactPhone != "" && contactPhone != undefined) {
      setUserData({ ...userData, c_cell_phone: contactPhone });
    }
  }, [contactPhone]);
  useEffect(() => {
    if (acontactPhone != "" && acontactPhone != undefined) {
      setUserData({ ...userData, a_c_cell_phone: acontactPhone });
    }
  }, [acontactPhone]);

  return (
    <div>
      <StepOne
        userData={userData}
        setUserData={setUserData}
        errorField={errorField}
        setErrorField={setErrorField}
        recordList={recordList}
        setRecordList={setRecordList}
        regType={regType}
        curDomain={curDomain}
      />
      <StepTwo
        userData={userData}
        setUserData={setUserData}
        errorField={errorField}
        setErrorField={setErrorField}
        homePhone={homePhone}
        setHomePhone={setHomePhone}
        cellPhone={cellPhone}
        setCellPhone={setCellPhone}
        aCellPhone={aCellPhone}
        setACellPhone={setACellPhone}
        aHomePhone={aHomePhone}
        setAHomePhone={setAHomePhone}
        contactPhone={contactPhone}
        setContactPhone={setContactPhone}
        acontactPhone={acontactPhone}
        setAContactPhone={setAContactPhone}
        regType={regType}
        curDomain={curDomain}
      />
    </div>
  );
};

export default StepInfo;
