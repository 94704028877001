import React from "react";
import { Input, Row, Col, Select } from "antd";

import "./SearchWidget.css";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
const { Search } = Input;
const { Option } = Select;
function SearchWidget(props) {
  const { dataList, onClear, onSearch } = props;

  return (
    <Row className="follower-cnt-txt" align="middle" justify="space-between">
      <Col>
        <span>Count: {dataList.length}</span>
      </Col>
      <Col>
        <Row gutter={32} align="middle">
          <Col>
            <Search
              placeholder={"Search name or email"}
              allowClear
              onSearch={(v) => {
                onSearch(v);
              }}
              enterButton
            />
          </Col>

          {/* <Col>
            <CommonButton
              onClick={() => {
                onClear();
              }}
            >
              Clear
            </CommonButton>
          </Col> */}
        </Row>
      </Col>
    </Row>
  );
}

export default SearchWidget;
