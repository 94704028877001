import React from "react";
import "./CommonButton.css";
const CommonButton = (props) => {
  const { onClick, customClass, style, isDiv = null } = props;
  if (isDiv == null) {
    return (
      <span
        style={style}
        className={`custom-button common-button ${
          customClass === undefined ? "" : customClass
        }`}
        onClick={onClick}
      >
        {props.children}
      </span>
    );
  } else {
    return (
      <div
        style={style}
        className={`custom-button common-button ${
          customClass === undefined ? "" : customClass
        }`}
        onClick={onClick}
      >
        {props.children}
      </div>
    );
  }
};

export default CommonButton;
