import React, { useEffect } from "react";
import { useState } from "react";
import { Row, Col, Input, message } from "antd";
import Icon from "@ant-design/icons";
import "./MainSlide.css";

import oneImg from "../../../assets/images/home/1.png";
import twoImg from "../../../assets/images/home/2.png";
import threeImg from "../../../assets/images/home/3.png";
import fourImg from "../../../assets/images/home/4.png";
import LogoImg from "../../../assets/images/tuxedocampimage.png";
import TestImg from "../../../assets/images/testback.jpg";
import clickIcon from "../../../assets/images/click_icon.png";
import AlertImg from "../../../assets/images/ic_alert.png";
import { ReactComponent as RightArrowIcon } from "../../../assets/icons/right-arrow-icon.svg";

import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { ReactComponent as userIcon } from "../../../assets/icons/ic_user.svg";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import NormalButton from "components/ButtonWidgets/NormalButton/NormalButton";
import InputPassword from "components/InputWidgets/InputPassword/InputPassword";
import ButtonWithIcon from "components/ButtonWidgets/ButtonWithIcon/ButtonWithIcon";
import { showMyAlertDialog } from "components/MyAlertDialog/showMyAlertDialog";
import { showChooseTypeDialog } from "../Layout/ChooseTypeDialog/showChooseTypeDialog";
import { ROUTE_REGISTER } from "navigation/CONSTANTS";

import { ValidateEmail } from "utils/GlobalFunctions";
import { apiLogin } from "services/userServices";
import { ROUTE_DASHBOARD } from "navigation/CONSTANTS";
import { useDispatch } from "react-redux";
import { updateUser } from "redux/actions/userActions";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [oneImg, twoImg, threeImg, fourImg];

const MainSlide = (props) => {
  const { resStatus, appInfo, curDomain } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [userDetail, setUserDetail] = useState({ email: "", password: "" });
  const [wrongList, setWrongList] = useState([]);
  const [email_err_msg, setEmailErrMsg] = useState("");
  const [password_err_msg, setPasswordErrMsg] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const validateFields = () => {
    const { email, password } = userDetail;
    let tmpWrongList = [];
    if (email === undefined || email === "") {
      tmpWrongList.push("email");
      setEmailErrMsg("This field is required");
    } else {
      if (ValidateEmail(email) === false) {
        tmpWrongList.push("email");
        setEmailErrMsg("Not a valid email address");
      }
    }
    if (password === undefined || password === "") {
      tmpWrongList.push("password");
      setPasswordErrMsg("This field is required");
    }
    setWrongList(tmpWrongList);
    return tmpWrongList.length === 0;
  };
  const onClickNext = () => {
    if (validateFields()) {
      apiLogin(userDetail)
        .then((res) => {
          if (res.result) {
            dispatch(updateUser({ ...res.user, token: res.msg }));
            history.push(ROUTE_DASHBOARD);
          } else {
            message.error(res.msg);
          }
        })
        .catch((err) => {
          message.error("Something went wrong");
        });
    }
  };
  const showAlertDlg = async () => {
    let dlgRes = await showMyAlertDialog({});
    if (dlgRes == null) return;
  };
  const showTypeDlg = async () => {
    let dlgRes = await showChooseTypeDialog({ appInfo, curDomain });
    if (dlgRes == null) return;
    localStorage.setItem("camper_registration_type", dlgRes)
    history.push(ROUTE_REGISTER)
  };
  return (
    <div className="main-slide">
      {/* <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <div className="alert-rect">
            <img src={AlertImg} className="alert-img" />
            {resStatus ? <>
              <div className="alert-text">
                <p>Registration open</p>
                <p>for Summer 2024</p>
              </div>
            </> : <>
              <div className="alert-text">
                <p>Registration is</p>
                <p> Currently Closed</p>
              </div>
            </>}
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <div className="slide-left">
            <div className="login-section">
              <img
                src={LogoImg}
                style={{
                  width: "auto",
                  height: "550px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </Col>
      </Row> */}
      <div className="home-top-title">{appInfo?.registration_info?.homepage_title}</div>
      <div className="home-main-back">
        <div className="home-reg-title">
          {resStatus ? appInfo?.registration_info?.homepage_announcement : "REGISTRATION is Currently Closed"}
        </div>
        <div className="home-main-back-img">
          {/* <img
            src={appInfo?.registration_info?.homepage_background}
            style={{
              width: "300px",
              height: "auto",
            }}
          /> */}
          <img
            src={appInfo?.registration_info?.homepage_background}
            style={{
              width: "100%",
              height: "auto",
            }}
          />
          <div className="logo-middle-rect">
            <Row justify={"space-between"} align={"top"}>
              {appInfo?.registration_info?.homepage_logo &&
                <Col md={6} sm={12}>
                  <div className="home-logo-img">
                    <img
                      src={appInfo?.registration_info?.homepage_logo}
                      className="home-logo-icon"
                    />
                  </div>
                </Col>}
              {appInfo?.registration_info?.homepage_info &&
                <Col md={6} sm={12}>
                  <div className="home-info-img">
                    <img
                      src={clickIcon}
                      className="home-click-icon"
                      onClick={() => {
                        window.open(appInfo?.registration_info?.homepage_info, '_blank');
                      }}
                    />
                  </div>
                </Col>}
            </Row>
          </div>
        </div>
        <div className="home-reg-title">
          <CommonButton style={{ padding: "5px 80px", border: "1px solid #fff", background: "transparent" }}
            onClick={() => {
              if (resStatus) {
                showTypeDlg();
              } else {
                showAlertDlg();
              }
            }}
          >Register</CommonButton>
        </div>
      </div>
      <Row justify={'left'} align={'middle'} className="slide-title padding-title" >
        <Col>
          <p style={{ margin: 'auto', textAlign: 'left' }}>
            Be organized, Be successful...BeClutch!
          </p>
        </Col>

      </Row>

      <p className="slide-description padding-description" style={{ marginTop: 20 }}>
        Meet the challenges of group organization and stay connected with
        BeClutch. Whether you are a leader or member you now have the ability to
        communicate and organize all group needs from one App. Handle
        communication via chats, coordinate schedules, calendar events, send
        alerts, receive reminders and manage finances with intuitive and easy to
        navigate interfaces.
      </p>
    </div>
  );
};

export default MainSlide;
