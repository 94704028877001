import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Divider, message, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { UpdateCurrentRoot } from "redux/actions/appActions";
import { ROUTE_DASHBOARD } from "navigation/CONSTANTS";
import { apiGetMemberList } from "services/dashboardService";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import ClubTable from "./ClubTable/ClubTable";
import "./Dashboard.css";
import SearchWidget from "./SearchWidget/SearchWidget";
import TopbarLayout from "layouts/TopbarLayout/TopbarLayout";
const Dashboard = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userDataStore = useSelector((x) => x.userDataStore);
  const [isBusy, setIsBusy] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [srcList, setSrcList] = useState([]);

  useEffect(() => {
    dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_DASHBOARD }));
    if (
      userDataStore.EMAIL != undefined &&
      userDataStore.EMAIL != "" &&
      userDataStore.token != undefined &&
      userDataStore.token != ""
    ) {
      getData();
    }
  }, []);

  const getData = () => {
    apiGetMemberList(userDataStore.EMAIL, userDataStore.token)
      .then((res) => {
        if (res.result) {
          let tmpList = [...res.data];
          setDataList(tmpList);
          setSrcList(tmpList);
        } else {
          setDataList([]);
          setSrcList([]);
          message.error(res.msg);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
      });
  };
  const onClear = () => {
    setDataList(dataList);
  };
  const onSearch = (v) => {
    let tmpList = [...srcList];
    if (v == "") {
      setDataList(tmpList);
    } else {
      setDataList(
        tmpList.filter((x) => {
          if (
            x.email.toLowerCase().includes(v.toLowerCase()) ||
            x.first_name.toLowerCase().includes(v.toLowerCase()) ||
            x.last_name.toLowerCase().includes(v.toLowerCase())
          ) {
            return x;
          }
        })
      );
    }
  };
  const onRegister = () => {};
  return (
    <div>
      <TopbarLayout />
      <div className="dashboard_page">
        <CommonDivWidget>
          <Spin spinning={isBusy}>
            <Divider
              style={{ borderTop: "4px solid var(--mainColor)" }}
            ></Divider>
            <SearchWidget
              dataList={dataList}
              onClear={onClear}
              onSearch={onSearch}
            />
            <ClubTable dataList={dataList} onRegister={onRegister} />
          </Spin>
        </CommonDivWidget>
      </div>
    </div>
  );
};

export default Dashboard;
