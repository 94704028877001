import React, { useEffect, useState } from "react";
import { Divider, Row, Col, Radio, Checkbox, message } from "antd";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";

const StepItemWidget = (props) => {
  const { itemInfo, onCheck, isLast, camperIndex, userData } = props;

  return (
    <div style={{ marginBottom: 10 }}>
      <div style={{ fontWeight: 600 }}>
        <div style={{ marginTop: 10, marginBottom: 5 }}>
          <Row align={"top"} justify="space-between" gutter={[16, 4]}>
            <Col md={13}>
              <Checkbox
                disabled={itemInfo.open == "0"}
                onChange={(e) => {
                  if (onCheck != undefined) {
                    onCheck(camperIndex, itemInfo.id);
                  }
                }}
                checked={userData.camperInfo[camperIndex].sel_trip.includes(
                  itemInfo.id
                )}
              >
                {itemInfo.title.split("\\n").map((section, secKey) => {
                  return <div key={secKey}>{section}</div>;
                })}
              </Checkbox>
            </Col>
            <Col
              md={4}
              style={
                itemInfo.open == "1"
                  ? { textAlign: "right", marginLeft: 25 }
                  : { textAlign: "right", marginLeft: 25, color: "rgba(0, 0, 0, 0.25)" }
              }
            >
              ${itemInfo.price}
            </Col>
            <Col
              md={5}
              style={
                itemInfo.open == "1"
                  ? { textAlign: "right", marginRight: 25 }
                  : { textAlign: "right", marginRight: 25, color: "rgba(0, 0, 0, 0.25)" }
              }
            >
              Remaining - {parseInt(itemInfo.max_registrations) - parseInt(itemInfo.current_registrations)}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
export default StepItemWidget;
