import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// images
import Logo from "../../../assets/images/logo-large.png";
import { ReactComponent as LoginIcon } from "../../../assets/icons/login-icon.svg";
import { Button, Col, Row } from "antd";
import ButtonWithIcon from "components/ButtonWidgets/ButtonWithIcon/ButtonWithIcon";
import { showMyAlertDialog } from "components/MyAlertDialog/showMyAlertDialog";
import { showChooseTypeDialog } from "./ChooseTypeDialog/showChooseTypeDialog";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTE_REGISTER } from "navigation/CONSTANTS";

const MainNav = (props) => {
  const { resStatus } = props;
  const [activeClass, setActiveClass] = useState("");
  const [activeBtnClass, setActiveBtnClass] = useState("");
  const history = useHistory();
  // add class when scrolldown
  useEffect(() => {
    // const handleScrollEvent = () => {
    //   const top = window.pageYOffset || document.documentElement.scrollTop;
    //   if (top > 50) {
    //     setActiveClass(`nav-active`);
    //     setActiveBtnClass(`btn-active`);
    //     return;
    //   }
    //   setActiveClass("");
    //   setActiveBtnClass("");
    // };

    // window.addEventListener("scroll", handleScrollEvent);

    // return () => window.removeEventListener("scroll", handleScrollEvent);
  }, []);
  const showAlertDlg = async () => {
    let dlgRes = await showMyAlertDialog({});
    if (dlgRes == null) return;
  };
  const showTypeDlg = async () => {
    let dlgRes = await showChooseTypeDialog({});
    if (dlgRes == null) return;
    localStorage.setItem("camper_registration_type", dlgRes)
    history.push(ROUTE_REGISTER)
  };
  return (
    <Row
      className={`nav ${activeClass}`}
      justify="space-between"
      align="middle"
      gutter={[16, 16]}
    >
      <Col>
        <Link to="/">
          <img src={Logo} alt="Beclutch" className="img-logo" />
        </Link>
      </Col>
      <Col>
        <Row gutter={16}>
          {/* <Col>
            <Link to="/login">
              <ButtonWithIcon
                text="Login"
                customClass={`nav-login-btn ${activeBtnClass}`}
              />
            </Link>
          </Col> */}
          {/* <Col>
            {resStatus ? (
              <ButtonWithIcon
                text="Register"
                style={{
                  minWidth: 180,
                  border: "1px solid var(--redColor)",
                  color: "var(--redColor)",
                }}
                onClick={() => {
                  showTypeDlg();
                }}
                customClass={`nav-login-btn ${activeBtnClass}`}
              />
            ) : (
              <ButtonWithIcon
                text="Register"
                onClick={() => {
                  showAlertDlg();
                }}
                customClass={`nav-login-btn ${activeBtnClass}`}
              />
            )}
          </Col> */}
        </Row>
      </Col>
    </Row>
  );
};

export default MainNav;
