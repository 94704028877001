import React, { useState } from "react";
import Layout from "pages/HomePage/Layout";
import "./WelcomePage.css";
import LogoImg from "../../assets/images/mark.png";
import { Col, message, Row } from "antd";
import { useDispatch } from "react-redux";
import { apiGetRegistrationInfo } from "services/dashboardService";
import { useEffect } from "react";
import { UpdateAppInfo } from "redux/actions/appActions";
const WelcomePage = () => {
  const dispatch = useDispatch();
  const [isBusy, setIsBusy] = useState(false);
  const [resStatus, setRecStatus] = useState(true);
  const [appName, setAppName] = useState(true);
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setIsBusy(true);
    let curDomain = window.location.hostname;
    if (curDomain.trim() == "localhost") {
      curDomain = "devtuxedocamp.beclutch.club";
    }
    apiGetRegistrationInfo(curDomain)
      .then((res) => {
        setIsBusy(false);
        if (res.result) {
          setAppName(res.data.registration_info.registration_name)
          dispatch(UpdateAppInfo({ appInfo: res.data }));
          if (res.data.registration_info?.status == "1") {
            setRecStatus(true);
          } else {
            setRecStatus(false);
          }
        }
      })
      .catch((err) => {
        setIsBusy(false);
        message.error("Something went wrong");
      });
  };
  return (
    <Layout resStatus={resStatus}>
      <div className="welcome">
        <Row
          style={{ marginTop: 50 }}
          gutter={[32, 16]}
          align={"middle"}
          justify={"center"}
        >
          {/* <Col>
            <Link to="/">
              <img
                src={LogoImg}
                style={{ width: "150px", height: "auto", cursor: "pointer" }}
              />
            </Link>
          </Col> */}
          <Col style={{ textAlign: "center" }}>
            <p className="slide-title" style={{ marginBottom: 0 }}>
              {appName}
            </p>
          </Col>
        </Row>
        <div className="welcome-title">Payment Successful</div>
        <div className="welcome-subtitle" style={{ marginTop: 50 }}>
          Thank you for Registering with {appName}
        </div>
        <div className="welcome-subtitle">A receipt will be emailed to you</div>
      </div>
    </Layout>
  );
};

export default WelcomePage;
