import React from "react";
import { Radio, Row, Col, Input } from "antd";
const BankPayment = (props) => {
  const { userData, setUserData, errorField, setErrorField } = props

  const onChangeAccountType = (e) => {
    setUserData({ ...userData, bank_type: e.target.value });
  }
  const onChangeHolderType = (e) => {
    setUserData({ ...userData, bank_holder_type: e.target.value });
  }
  return (
    <>
      <Row gutter={16}>
        <Col xs={24} sm={24}>
          <div style={{ paddingBottom: "5px" }}>Bank Name*</div>
          <Input
            value={userData.bank_name}
            className={errorField !== undefined && errorField.includes("bank_name") ? `common-input-error` : `common-input`}
            onChange={(e) => {
              setUserData({ ...userData, bank_name: e.target.value });
            }} />
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: 20 }}>
        <Col xs={24} sm={12}>
          <div style={{ paddingBottom: "5px" }}>ACCOUNT TYPE*</div>
          <Radio.Group onChange={onChangeAccountType} value={userData.bank_type} style={{ fontWeight: 400, marginTop: 10 }}>
            <Radio value={"checking"}>CHECKING</Radio>
            <Radio value={"savings"}>SAVINGS</Radio>
          </Radio.Group>
        </Col>
        <Col xs={24} sm={12}>
          <div style={{ paddingBottom: "5px" }}>BANK HOLDER TYPE*</div>
          <Radio.Group onChange={onChangeHolderType} value={userData.bank_holder_type} style={{ fontWeight: 400, marginTop: 10 }}>
            <Radio value={"personal"}>PERSONAL</Radio>
            <Radio value={"business"}>BUSINESS</Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: 20 }}>
        <Col xs={24} sm={12}>
          <div style={{ paddingBottom: "5px" }}>ROUTING NUMBER*</div>
          <Input
            value={userData.bank_routing}
            className={errorField !== undefined && errorField.includes("bank_routing") ? `common-input-error` : `common-input`}
            onChange={(e) => {
              if (errorField.includes("bank_routing")) {
                let errors = errorField.filter((x) => x != "bank_routing");
                setErrorField([...errors]);
              }
              setUserData({ ...userData, bank_routing: e.target.value });
            }} />
        </Col>
        <Col xs={24} sm={12}>
          <div style={{ paddingBottom: "5px" }}>ACCOUNT NUMBER*</div>
          <Input
            value={userData.bank_account}
            className={errorField !== undefined && errorField.includes("bank_account") ? `common-input-error` : `common-input`}
            onChange={(e) => {
              if (errorField.includes("bank_account")) {
                let errors = errorField.filter((x) => x != "bank_account");
                setErrorField([...errors]);
              }
              setUserData({ ...userData, bank_account: e.target.value });
            }} />
        </Col>
      </Row>
    </>
  );
};

export default BankPayment;
