import api from "./api.js"
export const apiLogin = async (userDetail) => {
  const params = new URLSearchParams();
  params.append("email", userDetail.email);
  params.append("pwd", userDetail.password);

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const { data } = await api.post("auth/login", params, config);

  return data;
};

